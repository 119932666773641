<template>
  <div class="home first-visit">
    <!-- <p class="hello">Hallo {{ code._name }}!</p> -->
    <!-- <img alt="Bambus" class="bambus" src="../assets/bambus.png" /> -->

    <template v-if="!$store.getters.getAuthed && $store.getters.getFirstVisit">
      <p class="hello bold">Meldet euch jetzt zurück!</p>
      <p class="small center">
        Gebt uns jetzt Bescheid ob ihr zur Hochzeit kommen werdet.
      </p>

      <v-divider class="my-5"></v-divider>

      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            Datum der Hochzeit
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            Deine Email
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 3" step="3">
            Rückmeldung
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4"> Bestätigung </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <p class="bold">
              Bitte gebt zur Bestätigung das Datum der kirchlichen Trauung von
              Lisa und Thomas ein!
            </p>

            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="sendData.date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="sendData.date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :change="checkDate(sendData.date)"
                  rounded
                  outlined
                  dense
                  class="input-content"
                ></v-text-field>
              </template>
              <v-date-picker v-model="sendData.date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false" class="mb-3">
                  Abbrechen
                </v-btn>
                <v-btn
                  class="input-content mb-3"
                  rounded
                  color="primary"
                  @click="$refs.dialog.save(sendData.date)"
                  @keyup.enter="e1 = 2"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-btn
              rounded
              class="elevation-0"
              color="primary"
              :disabled="!isValidDate"
              @click="e1 = 2"
            >
              Weiter
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <p>
              <strong>Bitte gib deine E-Mail Adresse ein.</strong> <br>Damit ihr auch in Zukunft die
              Daten nochmals anpassen könnt!
            </p>
            <p class="small">Du wirst KEINE E-Mail's darüber erhalten!</p>

            <v-form>
              <v-text-field
                label="E-Mail Adresse"
                v-model="sendData.email"
                v-on:change="checkEmailFirst"
                :rules="rules.email"
                required
                class="input-content"
                id="email"
                outlined
                dense
                rounded
                @keyup.enter="e1 = 3"

              ></v-text-field>
            </v-form>
            <v-btn
              :disabled="!checkEmailFirst"
              rounded
              color="primary"
              @click="e1 = 3"
            >
              Weiter
            </v-btn>

            <v-btn rounded @click="e1 = 1" text> Zurück </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-form>
              <v-select
                :items="data.items"
                v-model="sendData.items"
                required
                label="Rückmeldung"
                aria-placeholder="Rückmeldung"
                class="input-content"
                outlined
                dense
                rounded
              ></v-select>
              <v-row v-if="sendData.items">
                <v-col cols="12" md="6">
                  <v-select
                    :items="data.personen"
                    :rules="[(v) => !!v || '']"
                    required
                    v-model="sendData.personen"
                    label="Anzahl Erwachsene"
                    class="input-content"
                    outlined
                    dense
                    rounded
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :items="data.kinder"
                    required
                    v-model="sendData.kinder"
                    label="Anzahl Kinder unter 12*"
                    class="input-content"
                    outlined
                    dense
                    rounded
                  ></v-select>
                </v-col>
              </v-row>
              <v-text-field
                label="Wichtige Informationen **"
                v-model="sendData.info"
                v-on:change="checkInformation"
                :rules="rules.info"
                required
                class="input-content"
                id="input-text"
                outlined
                dense
                rounded
              ></v-text-field>
            </v-form>
            <p class="small">
              * Kinder über 12 zählen als Erwachsene. <br />** Psst... das
              Brautpaar liest mit!
            </p>
            <v-btn
              type="submit"
              :disabled="!checkFirstForm || checkInformation"
              rounded
              color="primary"
              @click="onSendData(data), (e1 = 4)"
            >
              Speichern
            </v-btn>

            <v-btn rounded @click="e1 = 2" text> Zurück </v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <p class="center bold">Vielen Dank für eure Rückantwort!</p>
            <p v-if="sendData.items" class="small center">
              Schön, dass ihr dabei seid!
            </p>
            <p v-if="!sendData.items" class="small center">
              Schade, dass ihr nicht dabei seid!
            </p>
            <p class="small center">
              Falls ihr später eure Daten nochmals anpassen möchtet, könnt ihr
              euch jederzeit mit eurem Code und der E-Mail Adresse erneut
              anmelden!
            </p>

            <h2>Eure Rückmeldung:</h2>

            <p class="bold">{{ itemstex }}</p>
            <p class="small" v-if="sendData.personen != '0'">
              <span style="font-weight: 700">Personen:</span>
              {{ sendData.personen }}
            </p>
            <p class="small" v-if="sendData.kinder != '0'">
              <span style="font-weight: 700">Kinder:</span>
              {{ sendData.kinder }}
            </p>
            <p class="small" v-if="sendData.info != ''">
              <span style="font-weight: 700">Informationen:</span>
              {{ sendData.info }}
            </p>
            <v-divider class="my-5"></v-divider>
            <v-btn @click="onSetSecData()" rounded color="primary">
              Daten ändern
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>

    <template v-if="!$store.getters.getFirstVisit && !$store.getters.getAuthed && this.code._email != '' ">
      <div class="home">
        <p class="hello">Willkommen zurück.</p>
        <v-divider class="my-5"></v-divider>
        <p class="center">Melde dich mit deiner E-Mail Adresse an.</p>

        <v-layout v-if="error">
          <v-flex>
            <app-alert @dismissed="onDismissed" :text="this.error"></app-alert>
          </v-flex>
        </v-layout>

        <v-form>
          <v-text-field
            v-model="secemail"
            v-on:change="checkEmail"
            class="input-content"
            :rules="rules.email"
            outlined
            dense
            rounded
            label="E-Mail-Adresse"
          ></v-text-field>
        </v-form>
        <v-btn
          class="btn"
          :disabled="!checkEmail"
          @click="onSecAuth()"
          color="primary"
          rounded
          dense
        >
          Los geht's!
        </v-btn>
      </div>
    </template>



    <template v-if="!$store.getters.getFirstVisit && !$store.getters.getAuthed && this.code._email == '' ">
      <div class="home">
        <p class="hello bold">Du hast dich bereits auf einem anderen Weg zurückgemeldet..</p>
        <v-divider class="my-5"></v-divider>
        <p class="center">Wenn du deine Rückmeldung nochmals anpassen möchtest, <br>dann melde dich bitte persönlich bei Lisa und Thomas!</p>
      
      </div>
    </template>



    <template v-if="$store.getters.getAuthed && !AuthedView">
      <div class="home">
        <p class="hello">Ändert eure Daten.</p>
        <v-divider class="my-5"></v-divider>
        <v-select
          :items="data.items"
          v-model="secData._items"
          class="input-content"
          label="Rückmeldung"
          aria-placeholder="Rückmeldung"
          outlined
          dense
          rounded
        ></v-select>
        <v-row v-if="secData._items">
          <v-col cols="12" md="6">
            <v-select
              :items="data.personen"
              v-model="secData._personen"
              label="Anzahl Erwachsene"
              class="input-content"
              outlined
              dense
              rounded
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :items="data.kinder"
              v-model="secData._kinder"
              label="Anzahl Kinder unter 12 *"
              class="input-content"
              outlined
              dense
              rounded
            ></v-select>
          </v-col>
        </v-row>
        <v-text-field
          label="Wichtige Informationen **"
          v-model="secData._info"
          v-on:change="checkInformation"
          :rules="rules.info"
          required
          class="input-content"
          id="input-text"
          outlined
          dense
          rounded
        ></v-text-field>
        <p class="small">
          * Kinder über 12 zählen als Erwachsene. <br />** Psst... das Brautpaar
          liest mit!
        </p>
        <v-btn
          rounded
          color="primary"
          :disabled="!checkChangeForm || checkInformation"
          @click="onUpdateData(), onChanceAuthedView()"
        >
          Rückmeldung updaten
        </v-btn>
        <v-btn @click="onChanceAuthedView(), onSecAuth()" rounded text>
          Abbrechen
        </v-btn>
      </div>
    </template>

    <template v-if="$store.getters.getAuthed && AuthedView">
      <div class="home">
        <p class="hello">Eure Rückmeldung:</p>
        <v-divider class="my-5"></v-divider>

        <p style="font-weight: 700">{{ secData._itemstex }}</p>
        <p class="small" v-if="secData._personen != '0'">
          <span style="font-weight: 700">Personen:</span>
          {{ secData._personen }}
        </p>
        <p class="small" v-if="secData._kinder != '0'">
          <span style="font-weight: 700">Kinder:</span> {{ secData._kinder }}
        </p>
        <p class="small" v-if="secData._info != ''">
          <span style="font-weight: 700">Informationen:</span>
          {{ secData._info }}
        </p>

        <v-btn rounded color="primary" @click="onChanceAuthedView()">
          Daten ändern
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "FirstVisit",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      e1: 1,
      isValidEmail: false,
      isValidDate: false,

      isFormValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: [
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "Bitte gebe eine gültige Email ein!",
        ],
        info: [
          (v) => !(/[%&#]/.test(v)) || "Bitte keine dieser Zeichen eingeben: % & #",
        ],
      },
      itemstex: "",
      data: {
        items: [
          {
            text: "Ich/Wir komme/n.",
            value: true,
          },
          {
            text: "Ich/Wir komme/n nicht",
            value: false,
          },
        ],
        personen: ["1", "2", "3", "4", "5"],
        kinder: ["0", "1", "2", "3", "4", "5"],
        date: new Date().toISOString().substr(0, 10),
      },

      sendData: {
        code: null,
        items: null,
        personen: null,
        kinder: null,
        email: null,
        info: null,
        date: new Date().toISOString().substr(0, 10),
      },

      secData: {
        _items: null,
      },

      AuthedView: true,

      secemail: null,

      menu: false,
      modal: false,
    };
  },

  computed: {
    code() {
      return this.$store.getters.getCode;
    },
    error() {
      return this.$store.getters.getError;
    },
    checkFirstForm() {
      if (
        (this.sendData.items && this.sendData.personen) ||
        this.sendData.items === false
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkEmail() {
      return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.secemail
      );
    },
    checkEmailFirst() {
      return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.sendData.email
      );
    },
    checkInformation() {
      return /[%&#]/.test(this.sendData.info);
    },

    checkChangeForm() {
      if (
        (this.secData._items && (this.secData._personen !== "0") ||
        !this.secData._items) && !(/[%&#]/.test(this.secData._info))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    checkMail(email) {
      console.log("CHECK email mail", this.code._email);
      if (this.code._email.toLowerCase() === email.toLowerCase()) {
        this.isValidEmail = false;
      } else {
        this.isValidEmail = true;
      }
    },
    checkDate(date) {
      console.log("Check date 2021-04-05", date);
      if ("2021-09-11" === date) {
        this.isValidDate = true;
      } else {
        this.isValidDate = false;
      }
    },

    async onSetSecData() {
      await this.$store.dispatch("secData", {
        code: this.$store.getters.getCode._code,
      });
      this.secData = this.$store.getters.getData;

      console.log("secData:", this.secData);
      console.log(this.data.items[0].value.toString(), this.secData._items);

      if (this.data.items[0].value.toString() === this.secData._items) {
        console.log("same");
        this.secData._items = this.data.items[0].value;
        this.secData._itemstex = this.data.items[0].text;
      } else {
        console.log("not same");
        this.secData._items = this.data.items[1].value;
        this.secData._itemstex = this.data.items[1].text;
        this.secData._personen = "0";
        this.secData._kinder = "0";
      }
      if (this.secData._kinder === null) {
        this.secData._kinder = "0";
      }
      if (this.secData._info === null) {
        this.secData._info = "";
      }

      console.log("null?", this.secData);

      this.$store.dispatch("secAuth", { auth: true });
      this.AuthedView = false;
    },

    onSendData() {
      if (
        this.sendData.items.toString() === this.data.items[0].value.toString()
      ) {
        this.sendData.items = true;
        this.itemstex = this.data.items[0].text;
        console.log("sendDAZA after send", this.sendData);
      } else {
        this.sendData.items = false;
        this.itemstex = this.data.items[1].text;

        this.sendData.personen = "0";
      }
      if (this.sendData.kinder === null) {
        this.sendData.kinder = "0";
      }
      if (this.sendData.info === null) {
        this.sendData.info = "";
      }

      this.sendData.code = this.$store.getters.getCode._code;
      this.sendData.email = this.sendData.email.toLowerCase();
      this.$store.dispatch("setRueckmeldung", { sendData: this.sendData });

      console.log("authed", this.$store.getters.getAuthed);

      this.secData._items = this.sendData.items;
      this.secData.personen = this.sendData.personen;
      this.secData.kinder = this.sendData.kinder;
      this.secData.info = this.sendData.info;
    },

    onUpdateData() {
      if (
        this.secData._items.toString() === this.data.items[0].value.toString()
      ) {
        this.secData._items = true;
        this.secData._itemstex = this.data.items[0].text;
      } else {
        this.secData._items = false;
        this.secData._itemstex = this.data.items[1].text;

        this.secData._personen = "0";
        this.secData._kinder = "0";
      }

      if (this.secData._kinder === null) {
        this.secData._kinder = "0";
      }
      if (this.secData._info === null) {
        this.secData._info = "";
      }

      this.$store.dispatch("updateRueckmeldung", { sendData: this.secData });

      this.secData = this.$store.getters.getData;
    },

    async onSecAuth() {
      console.log("secemail", this.$store.getters.getCode._email);
      if (this.secemail.toLowerCase() === this.$store.getters.getCode._email.toLowerCase()) {
        await this.$store.dispatch("secData", {
          code: this.$store.getters.getCode._code,
        });
        this.secData = this.$store.getters.getData;

        console.log("secData:", this.secData);

        console.log(this.data.items[0].value.toString(), this.secData._items);

        if (this.data.items[0].value.toString() === this.secData._items) {
          console.log("same");
          this.secData._items = this.data.items[0].value;
          this.secData._itemstex = this.data.items[0].text;
        } else {
          console.log("not same");
          this.secData._items = this.data.items[1].value;
          this.secData._itemstex = this.data.items[1].text;
          this.secData._personen = "0";
          this.secData._kinder = "0";
        }

        if (this.secData._kinder === null) {
          this.secData._kinder = "0";
        }
        if (this.secData._info === null) {
          this.secData._info = "";
        }

        console.log("null?", this.secData);

        this.$store.dispatch("secAuth", { auth: true });
      } else {
        this.$store.dispatch("secAuth", { auth: false });
      }
    },

    onChanceAuthedView() {
      this.AuthedView = !this.AuthedView;
      console.log("authView", this.AuthedView);
      console.log("AUTED?", this.$store.getters.getAuthed);
    },

    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
  created() {
    // if (this.$route.params.code) {
    this.$store.dispatch("checkCode", {
      code: this.$route.params.code,
      route: this.$route,
    });
    //
  },
};
</script>
